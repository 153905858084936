import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './User.scss'
import { connect } from 'kea'
import application from '@otavamedia/om-component-library/lib/kea/application'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import { Link } from '../components/general/util/Links'
import track from 'react-tracking'
import Helmet from 'react-helmet'
import Newsletter from '../components/general/newsletter/Newsletter'
import WP from '@otavamedia/om-component-library/lib/lib/WP'

export default @track({ gtmContext: ['User'] })
@connect({
  actions: [
    application, [
      'setRendered',
    ],
    auth, [
      'updateModal'
    ]
  ],
  props: [
    auth, [
      'userData',
      'loggedIn',
      'accessLevel'
    ],
  ],
})
  /**
   * This shows the user page for logged in users. Only place that contains the logout button.
   */
class User extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    userData: PropTypes.object,
    accessLevel: PropTypes.number,
    loggedIn: PropTypes.bool,
  }

  state = {
    loggingOut: false,
    logoutUrl: ''
  }

  constructor (props) {
    super()
    props.actions.setRendered(true)
    this.getLogoutUrl()
  }

  async getLogoutUrl () {
    const url = await WP.getForcedLogoutUrl()
    this.setState({ logoutUrl: url })
  }

  render () {
    const { userData, accessLevel, loggedIn } = this.props
    const helmet = <Helmet>
      <title>Oma tili - {window.om_constants.siteName}</title>
    </Helmet>
    if (this.state.loggingOut) {
      return <div styleName="account">
        {helmet}
        <div styleName="header">
          <p>Odota hetki...</p>
        </div>
      </div>
    }
    if (!loggedIn) {
      return <div styleName="account">
        {helmet}
        <div styleName="header">
          <h1>Oma tili</h1>
          <p>Et ole kirjautunut sisään</p>
        </div>
        <div styleName="two-col">
          <div styleName="left-col">
            <div styleName="bg center">
              <p styleName="no-padding">
                <a id="logout-button" styleName="button no-margin" href={WP.getLoginUrl()}>
                  <span>Kirjaudu sisään</span>
                </a>
              </p>
              <p><Link to={{ link: 'https://otavamedia.fi/asiakaspalvelu/#omat-tiedot-ja-tilaukset' }} styleName="link">Luo Otavamedia-tili</Link>
              </p>
              <p>tai siirry</p>
              <p><Link to={{ link: '/' }} styleName="link">{window.om_constants.siteNameGen} etusivulle</Link></p>
            </div>
            <div styleName="bg">
              <h2>Vastauksia yleisimpiin kysymyksiin ja ongelmiin.</h2>
              <p>Jo sinulla on kysymyksiä esimerkiksi rekisteröitymisestä, kirjautumisesta tai
                 digitilauksen tilasta, voit löytää niihin vastauksen nopeimmin
                 sivulta: <Link to={{ link: '/usein-kysyttyja-kysymyksia-tmn-verkkopalvelusta/' }}>Usein kysyttyjä kysymyksiä {window.om_constants.abbreviation}:n verkkopalvelusta.</Link></p>
            </div>
            <Newsletter showAlways={true}/>
          </div>
          <div styleName="right-col">
            <h2>Tarvitsetko apua kirjautumisessa?</h2>
            <p>Jos sinulla on ongelmia Otavamedia-tilin aktivoinnissa, otathan yhteyttä digipalveluumme.</p>
            <h3>Digipalvelu</h3>
            <p>Puhelin (09) 156 6227
              <br/>
               Avoinna ma–pe 8–16
              <br/>
              <a href="mailto:digi@otavamedia.fi">digi@otavamedia.fi</a>
              <br/>
              <br/>
               Asiakaspalvelu auttaa lehtitilauksiin liittyvissä asioissa, kuten laskutus- tai osoitteenmuutosasioissa ja tilapäisissä jakelunkeskeytyksissä.
            </p>
            <h3>Asiakaspalvelu</h3>
            <p>Puhelin (09) 156 665
              <br/>
               Avoinna ma–pe 8–17
              <br/>
              <a href="mailto:asiakaspalvelu@otavamedia.fi">asiakaspalvelu@otavamedia.fi</a>
            </p>
            <h3>Palaute tai juttuvinkki</h3>
            <p>Voit antaa toimitukselle palautetta tai lähettää juttuvinkin alla olevalla lomakkeella.</p>
          </div>
        </div>
      </div>
    }
    return <div styleName="account">
      {helmet}
      <div styleName="header">
        <h1>Oma tili</h1>
        <p>Tervetuloa {userData.name}</p>
      </div>
      <div styleName="two-col">
        <div styleName="left-col">
          <div styleName="bg">
            <h2>Käyttäjätiedot</h2>
            <Link to={{ link: 'https://otavamedia.fi/asiakaspalvelu/#omat-tiedot-ja-tilaukset' }} styleName="link right">Hallinnoi Otavamedia-tiliä</Link>
            <p><b>Sähköposti:</b> {userData.email}</p>
            <p><b>Nimimerkki:</b> {userData.name}</p>
            <a id="logout-button" href={this.state.logoutUrl} styleName="button">
              Kirjaudu ulos
            </a>
          </div>
          <div styleName="bg">
            <h2>Tilauksen tila</h2>
            <Link to={{ link: 'https://otavamedia.fi/asiakaspalvelu/#omat-tiedot-ja-tilaukset' }} styleName="link right">Hallinnoi tilausta</Link>
            <p><b>Tilaus:</b> {accessLevel > 2 ? 'Tilauksesi on voimassa' : 'Ei tilausta'}</p>
          </div>
          <Newsletter showAlways={true}/>
        </div>
        <div styleName="right-col">
          <h2>Tarvitsetko apua kirjautumisessa?</h2>
          <p>Jos sinulla on ongelmia Otavamedia-tilin aktivoinnissa, otathan yhteyttä digipalveluumme.</p>
          <h3>Digipalvelu</h3>
          <p>Puhelin (09) 156 6227
            <br/>
             Avoinna ma–pe 8–16
            <br/>
            <a href="mailto:digi@otavamedia.fi">digi@otavamedia.fi</a>
            <br/>
            <br/>
             Asiakaspalvelu auttaa lehtitilauksiin liittyvissä asioissa, kuten laskutus- tai osoitteenmuutosasioissa ja tilapäisissä jakelunkeskeytyksissä.
          </p>
          <h3>Asiakaspalvelu</h3>
          <p>Puhelin (09) 156 665
            <br/>
             Avoinna ma–pe 8–17
            <br/>
            <a href="mailto:asiakaspalvelu@otavamedia.fi">asiakaspalvelu@otavamedia.fi</a>
          </p>
          <h3>Palaute tai juttuvinkki</h3>
          <p>Voit antaa toimitukselle palautetta tai lähettää juttuvinkin alla olevalla lomakkeella.</p>
        </div>
      </div>
    </div>
  }
}
