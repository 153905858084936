import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'kea'
import isEqual from 'lodash/isEqual'
import trimEnd from 'lodash/trimEnd'
import debounceRender from 'react-debounce-render'
import track from 'react-tracking'

import { ThemeProvider } from 'styled-components'
import { is as errorIs, getType, logErrorBoundaryError } from '@otavamedia/om-component-library/lib/lib/errors'
import RenderedError from '../components/general/util/RenderedError'
import application from '@otavamedia/om-component-library/lib/kea/application'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import headerLogic from '@otavamedia/om-component-library/lib/kea/header'
import magazineStore from '@otavamedia/om-component-library/lib/kea/weeklyMagazine'
import galleryLogic from '@otavamedia/om-component-library/lib/kea/gallery'
import adsLogic from '@otavamedia/om-component-library/lib/kea/ads'
import Placeholder from './Placeholder'
import Index from './Index'
import Resolver from './Resolver'
import Archive from './Archive'
import Article from './Article'
import Catch from './Catch'
import OrderConfirmation from './OrderConfirmation'
import BestProductCollection from './BestProductCollection'
import TestBankCategory from './TestBankCategory'
import TestBank from './TestBank'
import Feedback from './Feedback'
import MagazineArchive from '../components/weeklyMagazine/MagazineArchive'
import Loading from '../components/general/util/Loading'
import AdminBar from '../components/adminbar/AdminBar'
import SettingsComponent from '../components/Settings'
import Header from '../components/views/Header'
import Footer from '../components/views/Footer'
import Search from '../components/general/search/Search'
import RemoteLogin from '../components/general/util/RemoteLogin'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import Gallery from '../components/gallery/Gallery'
import MagazineArchiveHighlightsPage from '../components/archive/MagazineArchiveHighlightsPage'
import DigiMagazineArchive from '../components/weeklyMagazine/DigiMagazineArchive'
import ThemeMagazineArchive from '../components/weeklyMagazine/ThemeMagazineArchive'
import './App.scss'
import Videos from './Videos'
import User from './User'
import WeeklyMagazine from '../components/weeklyMagazine/WeeklyMagazine'
import MagResolver from './MagResolver'
import Author from './Author'
import Latest from './Latest'
import { isEditorLikeUser } from '@otavamedia/om-component-library/lib/lib/userManagement'
import ProductCards from './ProductCards'
import ElkForm from '../components/forms/ElkForm'
import CatchClub from './CatchClub'
import FishForm from '../components/forms/FishForm'
import Readers from './Readers'
import Offer from './Offer'
import DeerForm from '../components/forms/DeerForm'
import StoryArchive from './StoryArchive'
import AdManager from '../components/general/ads/AdManager'

// Extract SASS variables so we can use them with styled-components
const themeSettings = {
  'content-width': '1440px',
  'footer-height': '540px',
  'mobile-nav-height': '4rem',
  'tablet-nav-height': '8.5rem',
  'tablet-nav-height-collapsed': 'mobile-nav-height',

  'body-font-size': '18',
  'body-line-height': '28',

  'block-bottom-margin': '2.5rem',
}

const themeColours = {
  'colour-light': 'rgb(255, 255, 255)',
  'colour-secondary': '#e58734',
  'colour-tertiary': '#305900',

  'colour-verydark': 'rgb(0, 0, 0)',
  'colour-dark': '#230000',
  'colour-lightgrey': '#f2f2f4',
  'colour-grey': '#979797',
  'colour-darkgrey': '#484e5d',

  /* Accent colours */
  'colour-link': '#1b4f9d',
  'colour-link-visited': 'darken(colour-link, 10%)',
  'colour-link-hover': 'darken(colour-link, 10%)',

  'colour-article-border': '#e5e5e5',
  'colour-header-background': '#ededee',
  'colour-lightgrey-background': '#fafafb',
  'colour-input-bg': '#fafafb',
  'colour-title-hover': '#565e70',
  'colour-tvtm-meta': '#666',
  'colour-bw-hover': '#c4c4c4',
  'image-caption-background': 'rgba(0, 0, 0, 0.5)',
  'table-main-header': 'white',
  'table-sub-header': '#e5e5e5',
  'table-border': 'colour-dark',
  'table-odd-row': '#f2f2f2',
  'sidestory-background': '#fafafb',
  'error-background': 'rgba(231, 3, 33, 0.06)',
  'fieldset-background': 'rgba(0, 0, 0, 0.02)',
  'colour-comment-border': '#d8d8d8',
  'colour-power-orange': '#ec6608',
  'colour-top-picks-bg': 'white',
}
const theme = Object.assign(themeSettings, themeColours)

@track({}, {
  dispatch: data => {
    const navData = { ...data }
    navData.targetElementPath = data.gtmContext
    delete navData.gtmContext
    delete navData.element
    delete navData.page_subtype
    navData.targetElement = navData.targetElementPath && navData.targetElementPath.length ? navData.targetElementPath[navData.targetElementPath.length - 1] : null
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(navData)

    if (data.element) {
      data.event = 'element_click'
      data.location = data.gtmContext && data.gtmContext.length ? data.gtmContext[data.gtmContext.length - 1] : null
      delete data.gtmContext
      delete data.targetElement
      delete data.gtmContext
      window.dataLayer.push(data)
    }
  }
})
@connect({
  actions: [
    application, [
      'setError',
      'setRendered',
      'updateSettings',
      'start',
    ],
    auth, [
      'startAuth',
      'updateUserFromWP',
    ],
    adsLogic, [
      'setupAds'
    ],
  ],
  props: [
    magazineStore, [
      'magazine as digimag',
    ],
    auth, [
      'userRoles',
      'loggedIn',
      'user',
      'premiumUser',
    ],
    application, [
      'resolverStatus',
      'ready as appReady',
      'contentTypes',
      'isArticleView',
      'isMagazineView',
      'isMagazineArticleView',
      'isFeatureArticle',
      'settings',
    ],
    headerLogic, [
      'isSecondLevelNavOpen',
      'ready as headerReady',
      'menus'
    ],
    galleryLogic, [
      'galleryOpen',
    ],
  ],
})
// @renderDebugger
class App extends Component {
  constructor (props) {
    super(props)
    const month = (new Date()).getMonth()
    if (month < 2 || month === 11) { // winter
      document.documentElement.style.setProperty('--icon-filter', 'hue-rotate(152deg) saturate(254%) brightness(60%) ')
      document.documentElement.style.setProperty('--colour-primary', '#1b4f9d')
      document.documentElement.style.setProperty('--colour-primary-darker', '#184890')
      document.documentElement.style.setProperty('--colour-primary-lighter', '#1f5cb7')
    } else if (month < 5) { // spring
      document.documentElement.style.setProperty('--colour-primary', '#7EA627')
      document.documentElement.style.setProperty('--colour-primary-darker', '#719523')
      document.documentElement.style.setProperty('--colour-primary-lighter', '#96c62f')
    } else if (month < 8) { // summer
      document.documentElement.style.setProperty('--icon-filter', 'hue-rotate(5deg) saturate(160%) brightness(57%)')
      document.documentElement.style.setProperty('--colour-primary', '#305900')
      document.documentElement.style.setProperty('--colour-primary-darker', '#294d00')
      document.documentElement.style.setProperty('--colour-primary-lighter', '#366600')
    } else { // autumn
      document.documentElement.style.setProperty('--icon-filter', 'hue-rotate(-51deg) saturate(110%)')
      document.documentElement.style.setProperty('--colour-primary', '#e58734')
      document.documentElement.style.setProperty('--colour-primary-darker', '#cc6d1a')
      document.documentElement.style.setProperty('--colour-primary-lighter', '#e8944b')
    }

    this.state = {
      routes: {},
      error: false,
    }
  }

  static propTypes = {
    updateSettings: PropTypes.func,
    settings: PropTypes.object,
    userRoles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    isSecondLevelNavOpen: PropTypes.bool,
    isArticleView: PropTypes.bool,
    isMagazineView: PropTypes.bool,
    isFeatureArticle: PropTypes.bool,
    isMagazineArticleView: PropTypes.bool,
    headless: PropTypes.bool,
    resolverStatus: PropTypes.number,
    appReady: PropTypes.bool,
    userReady: PropTypes.bool,
    headerReady: PropTypes.bool,
    menus: PropTypes.array,
    contentTypes: PropTypes.object,
    history: PropTypes.object,
    galleryOpen: PropTypes.bool,
    premiumUser: PropTypes.bool,
    rendered: PropTypes.bool,
    updating: PropTypes.bool, // SW caches the app locally, this is true when it's updating
    digimag: PropTypes.object,
    location: PropTypes.object,
    loggedIn: PropTypes.bool,
  }

  static defaultProps = {
    appReady: false,
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (isEqual(nextProps, this.props) && isEqual(nextState, this.state)) {
      return false
    }

    return true
  }

  loadOffline = () => {
    const url = new URL(window.location.href)
    url.searchParams.set('offline', true)

    window.location = url.toString()
  }

  /**
   * Error handler that intercepts errors and acts accordingly
   * Errors are returned to the catch block of the requesting function.
   */
  applicationErrorHandler = (error) => {
    const type = getType(error.message)
    const { setError } = this.actions
    let broadcastError = true

    if (errorIs.severe(type)) {
      // Setting the error property in the state prevents anything other than the error from showning.

      if (type === 'INIT_ERROR') {
        this.setState({ error })
        broadcastError = false
      }
    } else if (errorIs.significant(type)) {
      if (type === 'OFFLINE_ERROR') {
        // If an offline error is thrown, it's already known and located in settings.
        // Broadcasting it would allow two places for checking offline state
        broadcastError = false
      } else {
        console.error('Significant error', error)
      }
    } else if (errorIs.minor(type)) {
      console.error('Minor error', error)
      broadcastError = false
    }

    if (broadcastError) {
      // Add the error to state so it's accessible to every component
      setError(error)
    }

    // Return the error to the catch block
    return error
  }

  generateRouteComponents () {
    const { setErrorState, applicationErrorHandler } = this
    const { setRendered } = this.actions
    const additionalProps = {
      setErrorState,
      applicationErrorHandler,
      setRendered,
    }

    this.setState({
      routes: {
        remoteLogin: (props) => <RemoteLogin {...additionalProps} {...props} />,
        resolver: (props) => <Resolver {...additionalProps} {...props} />,
        magResolver: (props) => <MagResolver {...additionalProps} {...props} />,
        index: (props) => <Index {...additionalProps} {...props} />,
        author: (props) => <Author {...additionalProps} {...props} doneLoading={(value) => setRendered(value)} />,
        article: (props) => <Article {...additionalProps} {...props} doneLoading={(value) => setRendered(value)} />,
        search: (props) => <Search {...additionalProps} {...props} />,
        videos: (props) => <Videos {...additionalProps} {...props} />,
        catchClub: (props) => <CatchClub {...props}/>,
        catch: (props) => <Catch {...props} doneLoading={(value) => setRendered(value)} />,
        fish: (props) => <FishForm/>,
        elk: (props) => <ElkForm/>,
        deer: (props) => <DeerForm/>,
        readers: (props) => <Readers/>,
        stories: (props) => <StoryArchive {...additionalProps} {...props} />,
        offer: (props) => <Offer {...additionalProps} {...props} />,
        latest: (props) => <Latest {...additionalProps} {...props} />,
        mostRead: (props) => <Latest {...additionalProps} {...props} mostRead/>,
        digiMagArchive: (props) => <DigiMagazineArchive {...props} />,
        themeMagazines: (props) => <ThemeMagazineArchive {...props} />,
        latestMagazine: () => {
          // redirect to the actual latest magazine url
          const { menus } = this.props
          const magazineMenu = menus.find(menu => menu.taxonomy === 'printmag')
          return magazineMenu ? <Redirect to={magazineMenu.url}/> : null
        },
        weeklyMagazine: (props) => <WeeklyMagazine {...additionalProps} {...props} />,
        themeMagazine: (props) => <WeeklyMagazine {...additionalProps} {...props} isThemeMagazine={true} />,
        adMagazine: (props) => <WeeklyMagazine {...additionalProps} {...props} isAdMagazine={true} />,
        magazineArchive: (props) => <MagazineArchive {...additionalProps} {...props} />,
        magazineArchiveHighlights: (props) => <MagazineArchiveHighlightsPage {...additionalProps} {...props} />,
        feedback: (props) => {
          return <Redirect to="/anna-palautetta" /> // <Feedback {...additionalProps} {...props} form="mvp-palaute"/>
        },
        feedback2: (props) => {
          return <Feedback {...additionalProps} {...props} form="mvp-palaute-2"/>
        },
        keskustelu: () => (
          // Dirty side effect, but necessaryish.
          window.location.href = 'https://keskustelu.tekniikanmaailma.fi'
        ),
        digilehti: () => (
          <Redirect to="/lehti" />
        ),
        order: () => {
          window.location.href = window.om_constants.orderLink
          return null
        },
        tests: () => (
          <Redirect to="/testit/" />
        ),
        testWinners: () => (
          <Redirect to="/testivoittajat/" />
        ),
        settings: () => (
          <Placeholder>
            <SettingsComponent settings={this.props.settings} updateSettings={this.actions.updateSettings}/>
          </Placeholder>
        ),
        user: () => <User/>,
        bestproducts: (props) => {
          return <BestProductCollection {...additionalProps} {...props} />
        },
        cards: (props) => {
          return <ProductCards {...additionalProps} {...props} />
        },
        testbank: (props) => {
          return <TestBank {...additionalProps} {...props} />
        },
        testbankCategory: (props) => {
          return <TestBankCategory {...additionalProps} {...props} />
        },
        orderConfirmation: props => <OrderConfirmation {...additionalProps} {...props} />,
      }
    })
  }

  async componentDidMount () {
    WP.setErrorHandlerService(this.applicationErrorHandler)

    this.actions.start()
    AdManager.init()
    this.actions.startAuth()
    this.generateRouteComponents()

    if (window.dataLayer) {
      await window.dataLayer.push({ event: 'optimize.activate' })
    }
  }

  componentWillUnmount () {
    window.removeEventListener('offline', this.setNetworkStatus)
    window.removeEventListener('online', this.setNetworkStatus)
    WP.setErrorHandlerService(null)
  }

  componentDidCatch (error, info) {
    this.setState({ error })
    logErrorBoundaryError(error, info)
  }

  taxOrPostTypePrimaryRoute ([slug, obj]) {
    return (
      <Route path={`/${obj.url_base}`} exact key={slug} component={Archive} />
    )
  }

  taxOrPostTypeRoute ([slug, obj]) {
    return (
      <Route path={`/${obj.url_base}`} key={slug} component={Archive} />
    )
  }

  entryPointContextClasses = () => {
    const { isSecondLevelNavOpen, isMagazineView, isMagazineArticleView, digimag, isFeatureArticle } = this.props
    const classes = []
    const magazineExists = digimag && Object.keys(digimag).length

    if (isSecondLevelNavOpen) classes.push('second-level-nav')
    if (isMagazineView) classes.push('weekly-magazine')
    if (isMagazineArticleView && magazineExists) classes.push('weekly-magazine-article')
    if (isFeatureArticle) classes.push('feature-article')

    return classes
  }

  render () {
    const { error, routes } = this.state
    const {
      contentTypes,
      galleryOpen,
      headerReady,
      appReady,
      history,
      updating,
      location,
      loggedIn,
      userRoles,
      // isFeatureArticle,
    } = this.props
    const { postTypes, taxonomies } = contentTypes || {}

    if (!this.props.menus.length && appReady) {
      console.log('reports ready too early', this.props.menus)
    }

    if (updating) {
      const text = <p>Sivustoon on julkaistu päivitys viime käyntisi jälkeen, odota hetki kun lataamme sen.</p>

      return (
        <ThemeProvider theme={theme}>
          <Fragment>
            <Loading ready={updating} className="fullscreen" loadingText={text}>
              <p>Valmista tuli! Päivitä sivu jos tämä viesti ei häviä itsestään.</p>
            </Loading>
          </Fragment>
        </ThemeProvider>
      )
    }

    const entryPointClasses = ['app-entry-point', 'main-container'].concat(this.entryPointContextClasses())

    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <a className="skip-link screen-reader-text" href="#content">Siirry sisältöön</a>
          {/* isFeatureArticle ? null : */ <Loading ready={headerReady} className="hidden">
            <Header pathname={this.props.location.pathname} />
          </Loading>}

          <Loading ready={appReady} className="fullscreen">
            <Fragment>
              <div styleName={entryPointClasses.join(' ')} data-path={trimEnd(history.location.pathname, '/')}>

                <div styleName="content-row" id="content">
                  { error
                    ? (
                      <div styleName="applicationError">
                        <RenderedError error={error} />
                      </div>
                    )
                    : (
                      <Fragment>
                        <Switch>
                          <Route exact={true} path="/kategoria/testivoittaja/" component={routes.testWinners} />
                          <Route exact={true} path="/kategoria/testit/" component={routes.tests} />
                          <Route exact={true} path="/avainsana/testipankki/" component={routes.tests} />
                          <Route exact={true} path="/kategoria/tarinat/" component={routes.stories} />
                          {postTypes && Object.entries(postTypes).map(this.taxOrPostTypePrimaryRoute)}
                          {postTypes && Object.entries(postTypes).map(this.taxOrPostTypeRoute)}

                          {taxonomies && Object.entries(taxonomies).map(this.taxOrPostTypePrimaryRoute)}
                          {taxonomies && Object.entries(taxonomies).map(this.taxOrPostTypeRoute)}

                          <Route exact={true} path="/" component={routes.index} />
                          <Route exact={true} path="/haku/" component={routes.search} />
                          <Route path="/p" component={routes.article} />
                          <Route path="/keskustelu" component={routes.keskustelu} />
                          <Route path="/digilehti" component={routes.digilehti}/>
                          <Route path="/tarjous" component={routes.offer}/>
                          <Route path="/lehti/:number/:article" component={routes.magResolver}/>
                          <Route path="/lehti/:number" component={routes.weeklyMagazine}/>
                          <Route path="/lehti" component={routes.latestMagazine}/>
                          <Route path="/teemalehti/:number/:article" component={routes.magResolver}/>
                          <Route path="/teemalehti/:number" component={routes.themeMagazine}/>
                          <Route path="/digilehdet" component={routes.digiMagArchive}/>
                          <Route path="/teemalehdet" component={routes.themeMagazines}/>
                          <Route path="/tuotekortit" component={routes.cards}/>
                          <Route path="/kymppikerho" exact component={routes.catchClub}/>
                          <Route path="/kymppikerho/:cardId" component={routes.catch}/>
                          <Route path="/kymppikerho-lomake" component={routes.fish}/>
                          <Route path="/hirvikerho" exact component={routes.catchClub}/>
                          <Route path="/hirvikerho-lomake" component={routes.elk}/>
                          <Route path="/hirvikerho/:cardId" component={routes.catch}/>
                          <Route path="/peurakerho" exact component={routes.catchClub}/>
                          <Route path="/peurakerho/:cardId" component={routes.catch}/>
                          <Route path="/peurakerho-lomake" component={routes.deer}/>
                          <Route path="/lukijoilta" exact component={routes.readers}/>
                          <Route path="/uusimmat/:category?" component={routes.latest}/>
                          <Route path="/suosituimmat/:category?" component={routes.mostRead}/>
                          <Route path="/tilaa/" component={routes.order}/>

                          <Route path="/mainoslehti/:number/:article" component={routes.magResolver}/>
                          <Route path="/mainoslehti/:number" component={routes.adMagazine}/>
                          <Route path="/toimittaja/:name" component={routes.author}/>

                          <Route path="/arkisto/aarteita/:number" component={routes.magazineArchiveHighlights}/>
                          <Route path="/arkisto/aarteita" component={routes.magazineArchiveHighlights}/>
                          <Route path="/arkisto/:number" component={routes.magazineArchive}/>
                          <Route path="/arkisto" component={routes.magazineArchive}/>
                          <Route path="/palaute" component={routes.feedback}/>
                          <Route path="/anna-palautetta" component={routes.feedback2}/>
                          <Route path="/tili" component={routes.user}/>
                          <Route path="/asetukset" component={routes.settings}/>
                          <Route path="/testivoittajat/:category" component={routes.bestproducts} />
                          <Route path="/testivoittajat" component={routes.bestproducts} />
                          <Route path="/testit-:category/:subcategory?" component={routes.testbankCategory} />
                          <Route exact={true} path="/testit/" component={routes.testbank} />
                          <Route path="/kiitos-tilauksestasi" component={routes.orderConfirmation} />
                          <Route path="/remote-login/" component={routes.remoteLogin} />
                          <Route path={'/'} component={routes.resolver}/>
                        </Switch>
                      </Fragment>
                    ) }
                </div>
              </div>
                              <Footer />
                            {loggedIn && isEditorLikeUser(userRoles) ? <AdminBar location={location}/> : null}
              {galleryOpen && <Gallery/>}
            </Fragment>
          </Loading>
        </Fragment>
      </ThemeProvider>
    )
  }
}

export default debounceRender(App)
